import request from '../../shared/request'
import { generateFilters } from '../../helpers'

export function fetchAll(currentPage, itemsPerPage, filters = {}) {
  return request('v1/crud/aluno', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage,
      append: ['approved_certifications']
    },
    filters: generateFilters(filters, 'student')
  })
}

export function fetchAllSimple(currentPage, itemsPerPage, filters = {}) {
  return request('v1/services/aluno/listasimples', {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters, 'allor')
  })
}

export function fetchByFilter(filter = '', classId) {
  return request('v1/services/aluno/all', {
    qsParams: { 
      filter,
      id_turma: classId 
    }
  })
}

export function fetchEnrolled(currentPage, classId, filters = {}, orderBy = {}) {
  return request(`v1/services/turmas/${classId}/students`, {
    qsParams: {
      page: currentPage,
      order_by: orderBy.order_by,
      order: orderBy.order
    },
    filters: '&' + generateFilters(filters, 'enrolleds')
  })
}

export function exportEnrolled(classId, filters = {}) {
  return request(`v1/services/turmas/${classId}/export`, {
    filters: '?' + generateFilters(filters, 'enrolleds')
  })
}

export function fetchById(id) {
  return request(`v1/crud/aluno/${id}`)
}

export function add(formData) {
  return request(`v1/services/aluno/save`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function edit(formData) {
  return request(`v1/services/aluno/save/${formData.id}`, {
    method: 'PUT',
    body: JSON.stringify(formData)
  })
}

export function activeToggle(id, formData) {
  return request(`v1/crud/aluno/${id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/crud/aluno/${id}`, {
    method: 'DELETE'
  })
}

export function fetchByStudentId(id) {
  return request(`v1/services/aluno/enrollments/${id}`)
}

export function fetchScheduledTests(currentPage=1, itemsPerPage=15, filters = {}) {
  return request(`v1/services/aluno/scheduled-test`, {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters, 'service')
  });
};

export function updateScheduledToggleContact(scheduledTestId) {
  return request(`v1/services/aluno/scheduled-test/toggle-contacted/${scheduledTestId}`, {
    method: 'PUT'
  });
};

export function fetchMetrics(studentScheduledId) {
  return request(`v1/services/aluno/metrics/${studentScheduledId}`);
};

export function addStudentsToClass(classId, courseId, students) {
  const studentIds = students.map(student => ({
    id_usuario: student.id_usuario
  }));

  request(`v1/services/turmas/add-student/${classId}/${courseId}`, {
    method: 'POST',
    body: JSON.stringify(studentIds)
  });

  return students;
};
